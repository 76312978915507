.navbar {
    border-bottom-color: #00ff00;
    border-bottom-style: solid;
}

.navbar-toggler {
    border: 0;
}

.navbar-light .navbar-nav .nav-link.active {
    color: #00ff00;
}