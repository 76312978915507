.carousel {
    max-width: 50%;
}

.mobile {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 85px;
}

.post-text {
    margin-top: 25px;
}

.divider {
    width: 100%;
    height: 1.5px;
    background-color: #00ff00;
}

@media only screen and (max-width: 575px) {
    .mobile {
        display: block;
    }

    .carousel {
        max-width: 100%;
    }
}