#root {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: sinter, sans-serif;
}

.App {
    box-sizing: border-box;
    margin: 50px;
    margin-top: 75px;
}